import React, { useRef, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import Header from './components/header/Header';
import Portada from './components/portada/Portada';
import Fecha from './components/fecha/Fecha';
import Ceremonia from './components/ceremonia/Ceremonia';
import Celebracion from './components/celebracion/Celebracion';
import Invitados from './components/invitados/Invitados';
import Detalles from './components/detalles/Detalles';

import Fondo from './assets/fondo.png';
import OpenEffect from './components/openEffect/OpenEffect';
import Regalos from './components/regalos/Regalos';
import Carrousel from './components/carrousel/Carrousel';
import Footer from './components/footer/Footer';

import Cancion from './assets/cancion.mp3';

function App() {
  const audioRef = useRef(null);
  const imagenesLoaded = useRef(0);
  let cantidadImagenes = 2;

  function handleImageLoaded() {
    imagenesLoaded.current++;
  }

  function isReady() {
    console.log(cantidadImagenes, imagenesLoaded.current);

    return cantidadImagenes <= imagenesLoaded.current;
  }

  useEffect(() => {
    const img = new Image();
    img.src = Fondo;
    img.onload = handleImageLoaded;
  }, []);

  return (
    <Box sx={{
      height: window.innerHeight,
      overflowY: "auto",
      overflowX: "hidden",
    }}>
      <OpenEffect audioRef={audioRef} isReady={isReady} />
      <Box sx={{
        height: "100vh",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: -1,
      }}>
        <img src={Fondo} style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }} />
      </Box>
      <Header />
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "60px",
        paddingX: "10px",
      }}>
        <Portada onImageLoaded={handleImageLoaded} />
        <Fecha />
        <Ceremonia onImageLoaded={() => { }} />
        <Celebracion onImageLoaded={() => { }} />
        <Invitados onImageLoaded={() => { }} />
        <Detalles onImageLoaded={() => { }} />
        <Regalos />
        <Carrousel onImageLoaded={() => { }} />
        <Footer onImageLoaded={() => { }} />
      </Box>
      <audio ref={audioRef} src={Cancion} loop />
    </Box>
  );
}

export default App;